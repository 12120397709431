import React from "react";

export const ThankYou = () => {
  return (
    <div className="thankyou">
      {/* <img
        className="img1"
        src=""
      /> */}
      <img
        className="img1"
        src="https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/MTPL/thankyoupage/Thank%20you!%20We%20will%20get%20in%20touch%20with%20you%20shortly..svg"
      />
    </div>
  );
};
