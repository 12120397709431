import { useState, lazy, Suspense } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./style/main.css";
import { SectionSnap } from "./componets/custom/SectionSnap";
import { ThankYou } from "./componets/ThankYou";
import { Landing } from "./componets/Landing";
import { useRoutes } from "react-router-dom";
// import { Nav } from "./componets/Nav";
// import { Section1 } from "./componets/Sections/Section1";
// import { Section2 } from "./componets/Sections/Section2";
// import { Detailer } from "./componets/Detailer";
// import { DetailerMain } from "./componets/DetailerMain";
// import { Section9 } from "./componets/Sections/Section9";
// import { Section10 } from "./componets/Sections/Section10";
// import { Section11 } from "./componets/Sections/Section11";
// import { ConnectNow } from "./componets/ConnectNow";

function App() {
  const [show_page, setShow_page] = useState(true);
  const [Modal, setModal] = useState(false);
  const routes = [
    {
      path: "/",
      element: <Landing {...{ setShow_page, show_page, setModal, Modal }} />,
    },
    {
      path: "/thank-you",
      element: <ThankYou />,
    },
  ];
  const route = useRoutes(routes);
  return <>{route}</>;
}

export default App;
